import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 21 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      d: "M21 3.75v16.5a2.25 2.25 0 0 1-2.25 2.25h-3.998v-8.306h2.84L18 11.025h-3.248V9c0-.919.253-1.542 1.57-1.542H18V4.627a23 23 0 0 0-2.447-.127c-2.419 0-4.078 1.477-4.078 4.19v2.34h-2.85v3.168h2.855V22.5H2.25A2.25 2.25 0 0 1 0 20.25V3.75A2.25 2.25 0 0 1 2.25 1.5h16.5A2.25 2.25 0 0 1 21 3.75"
    }, null, -1)
  ])))
}
export default { render: render }